import { createAction, props } from '@ngrx/store';
import { SchemeType } from './scheme-types.model';

export enum SchemeTypesActionType {
  GET_ALL_SCHEME_TYPES = '[Scheme Types] Get All Scheme Types',
  GET_ALL_SCHEME_TYPES_SUCCESS = '[Scheme Types] Get All Scheme Types :: Success',
  GET_SCHEME_TYPE = '[Scheme Types] Get Scheme Type',
  GET_SCHEME_TYPE_SUCCESS = '[Scheme Types] Get Scheme Type :: Success',
  CREATE_SCHEME_TYPE = '[Scheme Types] Create Scheme Type',
  CREATE_SCHEME_TYPE_SUCCESS = '[Scheme Types] Create Scheme Type :: Success',
  UPDATE_SCHEME_TYPE = '[Scheme Types] Update Scheme Type',
  UPDATE_SCHEME_TYPE_SUCCESS = '[Scheme Types] Update Scheme Type :: Success',
  RESET_SCHEME_TYPE_UPDATED = '[Scheme Types] Reset Scheme Type Updated',
  DELETE_SCHEME_TYPE = '[Scheme Types] Delete Scheme Type',
  DELETE_SCHEME_TYPE_SUCCESS = '[Scheme Types] Delete Scheme Type :: Success',
  RESET_SCHEME_TYPE_DELETED = '[Scheme Types] Reset Scheme Type Deleted',
}

export const getAllSchemeTypes = createAction(SchemeTypesActionType.GET_ALL_SCHEME_TYPES);
export const getAllSchemeTypesSuccess = createAction(
  SchemeTypesActionType.GET_ALL_SCHEME_TYPES_SUCCESS,
  props<{ schemeTypes: SchemeType[] }>()
);
export const getSchemeType = createAction(
  SchemeTypesActionType.GET_SCHEME_TYPE,
  props<{ id: number }>()
);
export const getSchemeTypeSuccess = createAction(
  SchemeTypesActionType.GET_SCHEME_TYPE_SUCCESS,
  props<{ schemeType: SchemeType }>()
);
export const createSchemeType = createAction(
  SchemeTypesActionType.CREATE_SCHEME_TYPE,
  props<{ schemeType: SchemeType }>()
);
export const createSchemeTypeSuccess = createAction(
  SchemeTypesActionType.CREATE_SCHEME_TYPE_SUCCESS,
  props<{ schemeType: SchemeType }>()
);
export const updateSchemeType = createAction(
  SchemeTypesActionType.UPDATE_SCHEME_TYPE,
  props<{ schemeType: SchemeType }>()
);
export const updateSchemeTypeSuccess = createAction(
  SchemeTypesActionType.UPDATE_SCHEME_TYPE_SUCCESS,
  props<{ schemeType: SchemeType }>()
);
export const resetSchemeTypeUpdated = createAction(SchemeTypesActionType.RESET_SCHEME_TYPE_UPDATED);
export const deleteSchemeType = createAction(
  SchemeTypesActionType.DELETE_SCHEME_TYPE,
  props<{ id: number }>()
);
export const deleteSchemeTypeSuccess = createAction(SchemeTypesActionType.DELETE_SCHEME_TYPE_SUCCESS);
export const resetSchemeTypeDeleted = createAction(SchemeTypesActionType.RESET_SCHEME_TYPE_DELETED);
